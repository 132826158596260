<template>
    <div v-loading="is_pending" class="digital-signs">
        <div class="digital-signs-wrapper">
            <div class="digital-signs-wrapper-title">
                <h2>שליחת טופס מעדנייה</h2>
            </div>
            <div class="digital-signs-wrapper-user_name">
                <h3>שם המזמין</h3>
                <el-input style="width: 240px;" placeholder="הזן את שמך" v-model="data.user_name"></el-input>
            </div>
            <div class="digital-signs-wrapper-content">
                <form @submit.prevent="submitForm">
                    <div v-for="(sign, index) in data.signs" :key="index" class="sign-item">
                        <img :src="sign.img" :alt="sign.name" class="sign-image" />
                        <div class="sign-details">
                            <p class="sign-name">{{ sign.name }}</p>
                            <input type="number" v-model.number="sign.amount" min="0" class="sign-amount"
                                placeholder="כמות" />
                        </div>
                    </div>
                    <button type="submit" class="submit-btn">שלח טופס</button>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
import { slide_pop_error, alert } from '../../../Methods/Msgs'
import { user } from '../../../Methods/Utils'
import { projectFirestore, projectFunctions } from '../../../firebase/config';
import { ref, watchEffect } from 'vue'

export default {
    setup() {
        const is_pending = ref(false)

        const data = ref({
            branche: '',
            user_name: '',
            signs: []
        })


        const reset_signs = () => {
            data.value.signs.forEach(sign => sign.amount = 0)
        }

        const init = async () => {
            try {
                is_pending.value = true;
                const docs = await projectFirestore.collection('Applications').doc('aJQOk3g3Yx4G7iwfqL3j')
                    .collection('signs').get();

                data.value.signs = docs.docs
                    .map(doc => ({
                        ...doc.data(),
                        amount: 0 // Add amount field for user input
                    }))
                    .sort((a, b) => {
                        // Sort "שלט" first, then "פלסטיק"
                        if (a.type === "שלט" && b.type === "פלסטיק") return -1;
                        if (a.type === "פלסטיק" && b.type === "שלט") return 1;
                        return 0; // Keep original order if types are the same
                    });

                console.log(data.value);
            } catch (err) {
                console.error(err);
                slide_pop_error(err.message);
            } finally {
                is_pending.value = false;
            }
        }

        init();

        watchEffect(() => {
            data.value.branche = user.value?.branche || 'לא צויין';
        });

        const submitForm = async () => {
            try {
                is_pending.value = true
                const selectedSigns = data.value.signs.filter(sign => sign.amount > 0);
                console.log("Submitted data:", selectedSigns);
                if (!selectedSigns.length) throw new Error('חובה להזין כמויות!')
                if (!data.value.user_name) throw new Error('חובה להזין את שמך בתחילת הטופס!')
                const { branche, user_name } = data.value
                const doc = projectFirestore.collection('Applications').doc('aJQOk3g3Yx4G7iwfqL3j')
                            .collection('orders').doc();
                await doc.set({
                    createdAt: new Date(),
                    branche,
                    user_name,
                    signs: selectedSigns,
                    uid: doc.id
                })

                await sendEmail(selectedSigns)

                alert('success', 'הצלחה', 'הטופס נשלח בהצלחה!')

            } catch (err) {
                slide_pop_error(err.message)
            } finally {
                is_pending.value = false
                reset_signs()
            }
        };


        async function sendEmail(selectedSigns) {
            const htmlContent = `
                <!DOCTYPE html>
                <html lang="he">
                <head>
                    <meta charset="UTF-8">
                    <meta name="viewport" content="width=device-width, initial-scale=1.0">
                    <title>פרטי הזמנה</title>
                </head>
                <body style="direction: rtl; text-align: right; font-family: Arial, sans-serif; background-color: #f4f4f4; padding: 20px;">
                    
                    <div style="max-width: 600px; background: #fff; padding: 20px; border-radius: 10px; box-shadow: 0px 2px 5px rgba(0,0,0,0.2);">
                        
                        <h2 style="text-align: center; color: #333;">טופס הזמנה</h2>
                        
                        <p style="font-size: 16px;"><strong>סניף:</strong> ${data.value.branche}</p>
                        <p style="font-size: 16px;"><strong>שם משתמש:</strong> ${data.value.user_name}</p>

                        <h3 style="margin-top: 20px; border-bottom: 2px solid #ddd; padding-bottom: 5px;">פרטי השלטים</h3>

                        <table style="width: 100%; border-collapse: collapse; margin-top: 10px;">
                            <thead>
                                <tr style="background: #f8f8f8;">
                                    <th style="border: 1px solid #ddd; padding: 10px;">תמונה</th>
                                    <th style="border: 1px solid #ddd; padding: 10px;">שם השלט</th>
                                    <th style="border: 1px solid #ddd; padding: 10px;">כמות</th>
                                </tr>
                            </thead>
                            <tbody>
                                ${selectedSigns.map(sign => `
                                <tr>
                                    <td style="border: 1px solid #ddd; padding: 10px; text-align: center;">
                                        <img src="${sign.img}" alt="${sign.name}" style="width: 50px; height: 50px; object-fit: cover; border-radius: 5px;">
                                    </td>
                                    <td style="border: 1px solid #ddd; padding: 10px;">${sign.name}</td>
                                    <td style="border: 1px solid #ddd; padding: 10px; text-align: center;">${sign.amount}</td>
                                </tr>
                                `).join('')}
                            </tbody>
                        </table>

                        <p style="text-align: center; margin-top: 20px; font-size: 14px; color: #777;">
                            הודעה זו נשלחה אוטומטית ממערכת הזמנת שילוט מעדנייה.
                        </p>

                    </div>

                </body>
                </html>
            `;

            await projectFunctions.httpsCallable('send_email')({
                html: htmlContent,
                destinations: ['yavrosman@gmail.com'], 
                from: "מערכת הזמנת שילוט מעדנייה",
                title: `נשלח מסניף ${data.value.branche} ע"י המשתמש ${data.value.user_name}`
            });
        }


        return {
            is_pending,
            data,
            submitForm
        }
    }
}
</script>

<style scoped>
.digital-signs {
    width: 100%;
    height: 100%;
    padding: 0 6px;
}

.digital-signs-wrapper {
    width: 100%;
    max-width: 500px;
    height: 100%;
    margin: 0 auto;
    background: #fff;
    display: flex;
    flex-direction: column;
    border-radius: 5px;
}

.digital-signs-wrapper-title {
    width: 100%;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-size: 20px;
    font-weight: bold;
}
.digital-signs-wrapper-user_name {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 5px 0;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1); /* Bottom shadow */
}


.digital-signs-wrapper-content {
    width: 100%;
    flex: 1;
    overflow-y: auto; /* Allows scrolling */
    padding: 10px;
    
    /* Hide scrollbar for Chrome, Safari, Edge */
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none; /* Internet Explorer 10+ */
}

.digital-signs-wrapper-content::-webkit-scrollbar {
    display: none; /* Hide scrollbar for Chrome, Safari, Edge */
}


form {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.sign-item {
    display: flex;
    align-items: center;
    gap: 10px;
    border: 1px solid #ddd;
    padding: 10px;
    border-radius: 5px;
}

.sign-image {
    width: 75px;
    height: 75px;
    object-fit: cover;
    border-radius: 5px;
}

.sign-details {
    flex: 1;
    display: flex;
    flex-direction: column;
}

.sign-name {
    font-size: 16px;
    font-weight: bold;
}

.sign-amount {
    width: 100px;
    padding: 5px;
    border: 1px solid #ccc;
    border-radius: 5px;
    text-align: center;
}

.submit-btn {
    padding: 10px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    text-align: center;
}

.submit-btn:hover {
    background-color: #0056b3;
}
</style>
